import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import FooterDE from "../Symbols/FooterDE";
import { Helmet } from "react-helmet-async";
import "../../stylesheets/AboutUs.css";

class Environment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      docs: [],
    }
    this.intro = React.createRef();
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let count = 1;
    let records = [];
    for (let i = 0; i < count; i++) {
      const response = await fetch(
        `https://api.airtable.com/v0/appiUuQajhQ6Drt6x/Docs?view=Sorted`, { headers: { 'Authorization': 'Bearer patSxqK40aDpd0iY6.beedbae8aef0d8ef37eaa0268af4654eb5113643efb69777ef67367c2031ee09' } }
      );
      const data = await response.json();
      records = records.concat(data.records);
    }
    this.setState({
      docs: records,
    });
  };

  introScroll = () => {
    let element = this.intro.current;
    let headerOffset = 45;
    let elementPosition = element.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <div className="content">
        <Helmet>
          <title>Pancon - Umwelt</title>
          <meta
            name="description"
            content="Entdecken Sie die Qualitätszertifikate von Pancon wie
IATF 16949, RoHS-Konformität, ISO 9001, ... – Ihr Partner für
zertifizierte Steckverbinder und Verbindungslösungen nach höchsten Standards.
Vertrauen Sie auf geprüfte Qualität für Automotive und Industrie."
          />
          <meta property="og:title" content="Pancon - Umwelt" />
          <meta
            property="og:description"
            content="Entdecken Sie die Qualitätszertifikate von Pancon wie
IATF 16949, RoHS-Konformität, ISO 9001, ... – Ihr Partner für
zertifizierte Steckverbinder und Verbindungslösungen nach höchsten Standards.
Vertrauen Sie auf geprüfte Qualität für Automotive und Industrie."
          />
          <link
            rel="alternate"
            href="https://www.pancon.de/de/umwelt"
            hrefLang="de"
          ></link>
          <link
            rel="alternate"
            href="https://www.pancon.de/en/environment"
            hrefLang="en"
          ></link>
        </Helmet>
        <NavDE />
        <div className="hero env">
          <div className="red-bg-hero"></div>
          <div className="hero-content">
            <h1 className="hero-heading">Umwelt</h1>
            <p className="hero-desc">Übersicht unserer Zertifikate</p>
            <button onClick={this.introScroll} className="default inverted">
              Mehr erfahren
            </button>
          </div>
        </div>
        <div
          ref={this.intro}
          id="intro"
          className="section first flex center-h"
        >
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2>REACH</h2>
            </div>
            <div className="half">
              <h4>Beschreibung</h4>
              <p className="body">
                Verordnung (EG) Nr. 1907/2006 des Europäischen Parlaments und
                des Rates vom 18. Dezember 2006 zur Registrierung, Bewertung,
                Zulassung und Beschränkung chemischer Stoffe
                (REACH)Registration, Evaluation, Authorisation and Restriction
                of Chemicals (REACH) is a European Union Regulation of 18
                December 2006.[1] REACH addresses the production and use of
                chemical substances, and their potential impacts on both human
                health and the environment
              </p>
              <a href={this.state.docs[0] ? this.state.docs[0].fields.Document_DE[0].url : ""} target={"_blank"} className="default env">
                Download
              </a>
            </div>
            <div className="half right-text">
              <h4>Weitere Informationen</h4>
              <p className="body">
                <ul>
                  <li>
                    Die{" "}
                    <a
                      target={"_blank"}
                      href="http://echa.europa.eu/de/"
                      className="accented"
                    >
                      Europäische Chemikalienagentur
                    </a>{" "}
                    (ECHA, European Chemicals Agency) ist eine Behörde der EU,
                    die die technischen, wissenschaftlichen und administrativen
                    Aspekte bei der Registrierung, Bewertung und Zulassung von
                    Chemikalien regelt.
                  </li>
                  <li>
                    BDI - Bundesverband der Deutschen Industrie REACH- und der
                    CLP-Verordnung als{" "}
                    <a
                      target={"_blank"}
                      href="http://echa.europa.eu/de/"
                      className="accented"
                    >
                      BDI-Broschüre
                    </a>
                    .
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div className="section full env flex center-h center-v">
          <span className="bg-quote">
            "Look deep into nature, and then you will understand everything
            better."
          </span>
        </div>
        <div id="coc" className="section flex center-h">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2>Code of Conduct</h2>
            </div>
            <div>
              <a href={this.state.docs[0] ? this.state.docs[1].fields.Document_DE[0].url : ""} target={"_blank"} className="default env">
                Download
              </a>
            </div>
          </div>
        </div>
        <div id="rohs" className="section flex center-h">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2>RoHS I / RoHS II</h2>
            </div>
            <div className="half">
              <h4>Beschreibung</h4>
              <p className="body">
                Die EG-Richtlinien 2002/95/EG und 2011/65/EU zur Beschränkung
                der Verwendung bestimmter gefährlicher Stoffe in Elektro- und
                Elektronikgeräten (RoHS)The Directive on the restriction of the
                use of certain hazardous substances in electrical and electronic
                equipment 2002/95/EC & 2011/65/EC (RoHS).
              </p>
              <a href={this.state.docs[0] ? this.state.docs[2].fields.Document_DE[0].url : ""} target={"_blank"} className="default env">
                Download
              </a>
            </div>
            <div className="half right-text">
              <h4>Weitere Informationen</h4>
              <p className="body">
                <ul>
                  <li>
                    <a
                      target={"_blank"}
                      href="http://ec.europa.eu/environment/waste/rohs_eee/index_en.htm"
                      className="accented"
                    >
                      European Commission Environment Waste RoHS
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div id="iatf-iso" className="section flex center-h">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2>Weitere Zertifikate</h2>
            </div>
            <div className="third">
              <h4>IATF</h4>
              <a href={this.state.docs[0] ? this.state.docs[3].fields.Document_DE[0].url : ""} target={"_blank"} className="default env">
                Download
              </a>
            </div>
            <div className="third">
              <h4>ISO 9001</h4>
              <a href={this.state.docs[0] ? this.state.docs[4].fields.Document_DE[0].url : ""} target={"_blank"} className="default env">
                Download
              </a>
            </div>
            <div className="third">
              <h4>Markenanmeldung</h4>
              <a href={this.state.docs[0] ? this.state.docs[5].fields.Document_DE[0].url : ""} target={"_blank"} className="default env">
                Download
              </a>
            </div>
          </div>
        </div>
        <FooterDE />
      </div>
    );
  }
}

export default Environment;
