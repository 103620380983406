import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import FooterDE from "../Symbols/FooterDE";
import "../../stylesheets/Produkte.css";
import { Helmet } from "react-helmet-async";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";

class Zubehoer extends Component {
  constructor(props) {
    super(props);
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    this.state = {
      file: null,
      numPages: 0,
      pageNumber: 1,
      product: [],
    };
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  nextPage = () => {
    const currentPageNumber = this.state.pageNumber;
    let nextPageNumber;

    if (currentPageNumber + 1 > this.state.numPages) {
      nextPageNumber = 1;
    } else {
      nextPageNumber = currentPageNumber + 1;
    }

    this.setState({
      pageNumber: nextPageNumber,
    });
  };

  componentDidMount() {
    fetch(
      `https://api.airtable.com/v0/app1t0Kdg1xsbWoWn/tblOx9RgM1uGIBYxP/${window.location.pathname.split("/")[3]
      }`, { headers: { 'Authorization': 'Bearer patSxqK40aDpd0iY6.beedbae8aef0d8ef37eaa0268af4654eb5113643efb69777ef67367c2031ee09' } }
    )
      .then((resp) => resp.json())
      .then((data) => {
        this.setState({ product: data, file: data.fields.Datenblatt ? data.fields.Datenblatt[0].url : null });
      })
      .catch((err) => {
        // Error :(
      });

    this.props.handler("Zubehör")
  }

  render() {
    const { pageNumber, numPages } = this.state;
    if (
      this.state.product &&
      Object.keys(this.state.product).length !== 0 &&
      Object.getPrototypeOf(this.state.product) === Object.prototype
    ) {
      return (
        <div className="content">
          <Helmet>
            <title>Pancon - {this.state.product.fields.Klarname_DE}</title>
            <meta
              name="description"
              content="Pancon mit Sitz in Bad Homburg, produziert und vertreibt elektromechanische Komponenten seit 1968."
            />
            <meta
              property="og:title"
              content={"Pancon - " + this.state.product.fields.Klarname_DE}
            />
            <meta
              property="og:description"
              content="Pancon mit Sitz in Bad Homburg, produziert und vertreibt elektromechanische Komponenten seit 1968."
            />
          </Helmet>
          <NavDE />
          <div className="section first flex center-h center-v">
            <div className="medium flex center-h product-p">
              <div className="heading1-cont flex center-v center-h">
                <div className="heading-deco m-right-3"></div>
                <h1>{this.state.product.fields.Klarname_DE}</h1>
              </div>
              {this.state.product.fields.Bild ? (
                <div className="flex center-h m-bottom-3 tool-img">
                  <img src={this.state.product.fields.Bild[0].url}></img>
                </div>
              ) : (
                <div className="flex center-h m-bottom-3"></div>
              )}
              <div className="flex center-h full-width">
                <p className="body">
                  <pre>
                    {this.state.product.fields.Beschreibung_DE}
                  </pre>
                </p>
              </div>
              {this.state.file ? (
                <div className="flex col center-h m-top-4">
                  <h2>Datenblatt</h2>
                  <Document
                    file={this.state.file}
                    onLoadSuccess={this.onDocumentLoadSuccess}
                  >
                    <Page pageNumber={pageNumber} />
                  </Document>
                  <div id="navigation_controls" className="flex col center-h">
                    {this.state.file ? (
                      <p className="body">
                        Seite {pageNumber} von {numPages}
                      </p>
                    ) : null}
                    <div className="flex center-h">
                      <button onClick={this.nextPage} className="default">
                        Nächste Seite
                      </button>
                    </div>
                  </div>
                  <a
                    target="_blank"
                    href={this.state.product.fields.Datenblatt[0].url}
                  >
                    <div className="flex center-h marg-top">
                      <button className="default">Download</button>
                    </div>
                  </a>
                </div>
              ) : null}
            </div>
          </div>
          <FooterDE />
        </div>
      );
    }
  }
}

export default Zubehoer;
