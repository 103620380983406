import React, { Component } from "react";
import NavEN from "../Symbols/NavEN";
import FooterEN from "../Symbols/FooterEN";
import About1 from "../../assets/about_1.jpg";
import { Helmet } from "react-helmet-async";
import "../../stylesheets/AboutUs.css";
import { FaPeopleArrows } from "react-icons/fa";
import { FaProductHunt } from "react-icons/fa";
import { FaGlobe } from "react-icons/fa";

class AboutUsEN extends Component {
  constructor(props) {
    super(props);
    this.intro = React.createRef();
  }

  introScroll = () => {
    let element = this.intro.current;
    let headerOffset = 45;
    let elementPosition = element.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <div className="content">
        <Helmet>
          <title>Pancon - About Us</title>
          <meta
            name="description"
            content="Pancon distributes electromechanical components, whether
standard solutions or customized solutions. We have been working for years with
well-known customers from segments of the automotive and industrial sectors."
          />
          <meta property="og:title" content="Pancon - About Us" />
          <meta
            property="og:description"
            content="Pancon distributes electromechanical components, whether
standard solutions or customized solutions. We have been working for years with
well-known customers from segments of the automotive and industrial sectors."
          />
          <link
            rel="alternate"
            href="https://www.pancon.de/de/ueber-uns"
            hrefLang="de"
          ></link>
          <link
            rel="alternate"
            href="https://www.pancon.de/en/about-us"
            hrefLang="en"
          ></link>
        </Helmet>
        <NavEN />
        <div className="hero about">
          <div className="red-bg-hero"></div>
          <div className="hero-content">
            <h1 className="hero-heading">About us</h1>
            <p className="hero-desc">
              Pancon, based in Bad Homburg, Germany, has been manufacturing and
              distributing electromechanical components since 1968
            </p>
            <button onClick={this.introScroll} className="default inverted">
              Learn more
            </button>
          </div>
        </div>
        <div ref={this.intro} id="intro" className="section flex center-h">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2>History</h2>
            </div>
            <div className="half">
              <p className="body">
                <ul>
                  <li>1968-1997 Connector Division of Panduit / USA</li>
                  <li>1997-2011 ITW / USA</li>
                  <li>2011-2019 Milestone Partners / USA</li>
                  <li>2020 LIB Beteiligungsgesellschaft mbH / Germany</li>
                </ul>
              </p>
            </div>
            <div className="half w-image">
              <img src={About1}></img>
            </div>
          </div>
        </div>
        <div className="section full about-1 flex center-h center-v">
          <div className="half about-img-2"></div>
          <div className="half red-bg">
            <h4>About us</h4>
            <h2>Pancon - The Company</h2>
            <ul>
              <li>Owner-managed company</li>
              <li>
                Future orientation through development and production of new
                product lines
              </li>
              <li>
                Motivating environment for employees {"&"} partners - More than
                90% of employees have been with the company for at least 5
                years, some even for more than 40 years
              </li>
              <li>Solid foundation of values</li>
              <li>
                Innovative manufacturer for electronic connectors in Europe
              </li>
              <li>
                Stable and long-standing customer base from various industries
              </li>
              <li>Financial security through a high equity ratio</li>
            </ul>
          </div>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2>International orientation</h2>
            </div>
            <div className="half">
              <h4>We produce in Europe and Asia</h4>
              <p className="body">
                With our three production sites in Europe and Asia, we are able
                to meet the requirements of our customers through international
                logistics and the use of the capacities of our distributors, we
                can deliver quickly and reliably.
              </p>
            </div>
            <div className="half right-text">
              <h4>Our customers produce in</h4>
              <p className="body">
                <ul>
                  <li>Europe</li>
                  <li>North America / South America</li>
                  <li>Asia</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div className="section full about">
          <div className="center">
            <h4>Facts and figures</h4>
          </div>
          <div className="container flex center-v spa">
            <div className="third flex center-v col">
              <FaPeopleArrows size={48} />
              <p>{">"} 100 Customers</p>
              <span>in 30 countries</span>
            </div>
            <div className="third flex center-v col">
              <FaProductHunt size={48} />
              <p>10.000 Products</p>
              <span>active</span>
            </div>
            <div className="third flex center-v col">
              <FaGlobe size={48} />
              <p>3 Production sites</p>
              <span>worldwide</span>
            </div>
          </div>
        </div>
        <FooterEN />
      </div>
    );
  }
}

export default AboutUsEN;
