import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import FooterDE from "../Symbols/FooterDE";
import "../../stylesheets/Produkte.css";
import { Helmet } from "react-helmet-async";

class Tool extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: [],
    };
  }

  componentDidMount() {
    fetch(
      `https://api.airtable.com/v0/app1t0Kdg1xsbWoWn/tblOx9RgM1uGIBYxP/${window.location.pathname.split("/")[3]
      }`, { headers: { 'Authorization': 'Bearer patSxqK40aDpd0iY6.beedbae8aef0d8ef37eaa0268af4654eb5113643efb69777ef67367c2031ee09' } }
    )
      .then((resp) => resp.json())
      .then((data) => {
        this.setState({ product: data });
      })
      .catch((err) => { });

    this.props.handler("Tools")
  }

  render() {
    if (
      this.state.product &&
      Object.keys(this.state.product).length !== 0 &&
      Object.getPrototypeOf(this.state.product) === Object.prototype
    ) {
      return (
        <div className="content">
          <Helmet>
            <title>Pancon - {this.state.product.fields.Klarname_DE}</title>
            <meta
              name="description"
              content="Pancon mit Sitz in Bad Homburg, produziert und vertreibt elektromechanische Komponenten seit 1968."
            />
            <meta
              property="og:title"
              content={"Pancon - " + this.state.product.fields.Klarname_DE}
            />
            <meta
              property="og:description"
              content="Pancon mit Sitz in Bad Homburg, produziert und vertreibt elektromechanische Komponenten seit 1968."
            />
          </Helmet>
          <NavDE />
          <div className="section first flex center-h center-v">
            <div className="medium flex">
              <div className="heading1-cont flex center-v center-h">
                <div className="heading-deco m-right-3"></div>
                <h1>
                  {this.state.product.fields.Klarname_DE +
                    " | " +
                    this.state.product.fields.Name}
                </h1>
              </div>
              <div className="flex center-h m-bottom-3 center-v tool-img">
                <img
                  src={this.state.product.fields.Bild[0].url}
                ></img>
              </div>
              <div className="flex center-h">
                <p className="body">
                  <pre>
                    {this.state.product.fields.Beschreibung_DE}
                  </pre>
                </p>
              </div>
            </div>
          </div>
          <FooterDE />
        </div>
      );
    }
  }
}

export default Tool;
