import React, { Component } from "react";
import logo from "../../assets/pancon_logo_large.png";
import { Link } from "react-router-dom";

class ProductCard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      this.props.lang === "de/produkte" && this.props.product.fields.Published && this.props.product.fields.Bild == undefined ?
        <Link className="card-cont" to={"/" + this.props.lang + "/" + this.props.product.id}>
          <div className="card">
            <div className="card-left part-20 flex col center-v center-h">
              <img src={logo} width="96" height="96"></img>
            </div>
            <div className="card-divider"></div>
            <div className="card-right flex part-80">
              <div className="card-title-cont flex center-h col">
                <h3 className="card-title">{this.props.product.fields.Name_Neu}</h3>
                <span className="alternate-title">{this.props.product.fields.Beschreibung_DE_Neu}</span>
              </div>
              <div className="card-desc flex align-left col">
                <span className="prod-attr">{this.props.product.fields.Kategorie}</span>
                <span className="prod-attr-desc">Kategorie</span>
              </div>
              <div className="card-desc flex align-left col">
                <span className="prod-attr">{this.props.product.fields.Produktreihe}</span>
                <span className="prod-attr-desc">Produktreihe</span>
              </div>
              <div className="card-desc flex align-left col">
                <span className="prod-attr">{this.props.product.fields.Pitch}</span>
                <span className="prod-attr-desc">Pitch</span>
              </div>
              <div className="card-desc flex align-left col">
                <span className="prod-attr">{this.props.product.fields.Number_of_Rows}</span>
                <span className="prod-attr-desc">Reihenanzahl</span>
              </div>
              <div className="card-desc flex align-left col">
                <span className="prod-attr">{this.props.product.fields.Gender_DE}</span>
                <span className="prod-attr-desc">Geschlecht</span>
              </div>
              <div className="card-desc flex align-left col">
                <span className="prod-attr">{this.props.product.fields.Polarized_DE}</span>
                <span className="prod-attr-desc">Polarisiert</span>
              </div>
              <div className="card-desc flex align-left col">
                <span className="prod-attr">{this.props.product.fields.Orientation}</span>
                <span className="prod-attr-desc">Orientierung</span>
              </div>
              <div className="card-desc flex align-left col">
                <span className="prod-attr">{this.props.product.fields.Connectivity_DE}</span>
                <span className="prod-attr-desc">Konnektivität</span>
              </div>
            </div>
          </div>
        </Link>
        : this.props.lang === "de/produkte" && this.props.product.fields.Published && this.props.product.fields.Bild != undefined ?
          <Link className="card-cont" to={"/" + this.props.lang + "/" + this.props.product.id}>
            <div className="card">
              <div className="card-left part-20 flex col center-v center-h">
                <img className="product-img" src={this.props.product.fields.Bild[0].url}></img>
              </div>
              <div className="card-divider"></div>
              <div className="card-right flex part-80">
                <div className="card-title-cont flex center-h col">
                  <h3 className="card-title">{this.props.product.fields.Name_Neu}</h3>
                  <span className="alternate-title">{this.props.product.fields.Beschreibung_DE_Neu}</span>
                </div>
                <div className="card-desc flex align-left col">
                  <span className="prod-attr">{this.props.product.fields.Kategorie}</span>
                  <span className="prod-attr-desc">Kategorie</span>
                </div>
                <div className="card-desc flex align-left col">
                  <span className="prod-attr">{this.props.product.fields.Produktreihe}</span>
                  <span className="prod-attr-desc">Produktreihe</span>
                </div>
                <div className="card-desc flex align-left col">
                  <span className="prod-attr">{this.props.product.fields.Pitch}</span>
                  <span className="prod-attr-desc">Pitch</span>
                </div>
                <div className="card-desc flex align-left col">
                  <span className="prod-attr">{this.props.product.fields.Number_of_Rows}</span>
                  <span className="prod-attr-desc">Reihenanzahl</span>
                </div>
                <div className="card-desc flex align-left col">
                  <span className="prod-attr">{this.props.product.fields.Gender_DE}</span>
                  <span className="prod-attr-desc">Geschlecht</span>
                </div>
                <div className="card-desc flex align-left col">
                  <span className="prod-attr">{this.props.product.fields.Polarized_DE}</span>
                  <span className="prod-attr-desc">Polarisiert</span>
                </div>
                <div className="card-desc flex align-left col">
                  <span className="prod-attr">{this.props.product.fields.Orientation}</span>
                  <span className="prod-attr-desc">Orientierung</span>
                </div>
                <div className="card-desc flex align-left col">
                  <span className="prod-attr">{this.props.product.fields.Connectivity_DE}</span>
                  <span className="prod-attr-desc">Konnektivität</span>
                </div>
              </div>
            </div>
          </Link>
          : this.props.lang === "en/products" && this.props.product.fields.Published && this.props.product.fields.Bild == undefined ?
            <Link className="card-cont" to={"/" + this.props.lang + "/" + this.props.product.id}>
              <div className="card">
                <div className="card-left part-20 flex col center-v center-h">
                  <img src={logo} width="96" height="96"></img>
                </div>
                <div className="card-divider"></div>
                <div className="card-right flex part-80">
                  <div className="card-title-cont flex center-h col">
                    <h3 className="card-title">{this.props.product.fields.Name_Neu}</h3>
                    <span className="alternate-title">{this.props.product.fields.Beschreibung_EN_Neu}</span>
                  </div>
                  <div className="card-desc flex align-left col">
                    <span className="prod-attr">{this.props.product.fields.Kategorie}</span>
                    <span className="prod-attr-desc">Category</span>
                  </div>
                  <div className="card-desc flex align-left col">
                    <span className="prod-attr">{this.props.product.fields.Produktreihe}</span>
                    <span className="prod-attr-desc">Series</span>
                  </div>
                  <div className="card-desc flex align-left col">
                    <span className="prod-attr">{this.props.product.fields.Pitch}</span>
                    <span className="prod-attr-desc">Pitch</span>
                  </div>
                  <div className="card-desc flex align-left col">
                    <span className="prod-attr">{this.props.product.fields.Number_of_Rows}</span>
                    <span className="prod-attr-desc">Number of Rows</span>
                  </div>
                  <div className="card-desc flex align-left col">
                    <span className="prod-attr">{this.props.product.fields.Gender_EN}</span>
                    <span className="prod-attr-desc">Gender</span>
                  </div>
                  <div className="card-desc flex align-left col">
                    <span className="prod-attr">{this.props.product.fields.Polarized_EN}</span>
                    <span className="prod-attr-desc">Polarized</span>
                  </div>
                  <div className="card-desc flex align-left col">
                    <span className="prod-attr">{this.props.product.fields.Orientation}</span>
                    <span className="prod-attr-desc">Orientation</span>
                  </div>
                  <div className="card-desc flex align-left col">
                    <span className="prod-attr">{this.props.product.fields.Connectivity_EN}</span>
                    <span className="prod-attr-desc">Connectivity</span>
                  </div>
                </div>
              </div>
            </Link>
            : this.props.product.fields.Published ?
              <Link className="card-cont" to={"/" + this.props.lang + "/" + this.props.product.id}>
                <div className="card">
                  <div className="card-left part-20 flex col center-v center-h">
                    <img className="product-img" src={this.props.product.fields.Bild[0].url}></img>
                  </div>
                  <div className="card-divider"></div>
                  <div className="card-right flex part-80">
                    <div className="card-title-cont flex center-h col">
                      <h3 className="card-title">{this.props.product.fields.Name_Neu}</h3>
                      <span className="alternate-title">{this.props.product.fields.Beschreibung_EN_Neu}</span>
                    </div>
                    <div className="card-desc flex align-left col">
                      <span className="prod-attr">{this.props.product.fields.Kategorie}</span>
                      <span className="prod-attr-desc">Category</span>
                    </div>
                    <div className="card-desc flex align-left col">
                      <span className="prod-attr">{this.props.product.fields.Produktreihe}</span>
                      <span className="prod-attr-desc">Series</span>
                    </div>
                    <div className="card-desc flex align-left col">
                      <span className="prod-attr">{this.props.product.fields.Pitch}</span>
                      <span className="prod-attr-desc">Pitch</span>
                    </div>
                    <div className="card-desc flex align-left col">
                      <span className="prod-attr">{this.props.product.fields.Number_of_Rows}</span>
                      <span className="prod-attr-desc">Number of Rows</span>
                    </div>
                    <div className="card-desc flex align-left col">
                      <span className="prod-attr">{this.props.product.fields.Gender_EN}</span>
                      <span className="prod-attr-desc">Gender</span>
                    </div>
                    <div className="card-desc flex align-left col">
                      <span className="prod-attr">{this.props.product.fields.Polarized_EN}</span>
                      <span className="prod-attr-desc">Polarized</span>
                    </div>
                    <div className="card-desc flex align-left col">
                      <span className="prod-attr">{this.props.product.fields.Orientation}</span>
                      <span className="prod-attr-desc">Orientation</span>
                    </div>
                    <div className="card-desc flex align-left col">
                      <span className="prod-attr">{this.props.product.fields.Connectivity_EN}</span>
                      <span className="prod-attr-desc">Connectivity</span>
                    </div>
                  </div>
                </div>
              </Link>
              : <div></div>
    );
  }
}

export default ProductCard;
