import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import ReactGA from 'react-ga4';
import CookieConsent from "react-cookie-consent";
import { HelmetProvider } from "react-helmet-async";

ReactGA.initialize('G-QH059LWL73');

const root = ReactDOM.createRoot(document.getElementById('root'));
if (window.location.href.indexOf("/en/") != -1) {
  root.render(
    <BrowserRouter>
      <CookieConsent
        location="bottom"
        buttonText="Got it"
        cookieName="cookie_consent_pancon"
        style={{ background: "#ffffff", color: "#000000" }}
        buttonStyle={{ color: "#ffffff", background: "#d6112b", fontSize: "15px", padding: "10px 20px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </BrowserRouter>
  );
} else {
  root.render(
    <BrowserRouter>
      <CookieConsent
        location="bottom"
        buttonText="Verstanden"
        cookieName="cookie_consent_pancon"
        style={{ background: "#ffffff", color: "#000000" }}
        buttonStyle={{ color: "#ffffff", background: "#d6112b", fontSize: "15px", padding: "10px 20px" }}
        expires={150}
      >
        Diese Webseite verwendet Cookies um die Benutzererfahrung zu verbessern.{" "}
      </CookieConsent>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </BrowserRouter>
  );
}
