import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import FooterDE from "../Symbols/FooterDE";
import DistributorEl from "../elements/DistributorEl";
import "../../stylesheets/Distribution.css";
import { Helmet } from "react-helmet-async";

class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="content">
        <Helmet>
          <title>Pancon - 404</title>
          <meta
            name="description"
            content="Pancon mit Sitz in Bad Homburg, produziert und vertreibt elektromechanische Komponenten seit 1968."
          />
          <meta property="og:title" content="Pancon - 404" />
          <meta
            property="og:description"
            content="Pancon mit Sitz in Bad Homburg, produziert und vertreibt elektromechanische Komponenten seit 1968."
          />
          <link
            rel="alternate"
            href="https://www.pancon.de/de/distribution"
            hrefLang="de"
          ></link>
          <link
            rel="alternate"
            href="https://www.pancon.de/en/distribution"
            hrefLang="en"
          ></link>
        </Helmet>
        <NavDE />
        <div className="section first less-bot">
          <div className="heading1-cont flex center-v center-h">
            <div className="heading-deco m-right-3"></div>
            <h1>404 - Seite nicht gefunden</h1>
          </div>
          <div className="flex center-h marg-top">
            <p className="body">
              Die Seite nach der Sie suchen existiert nicht oder wurde an einen anderen Pfad verschoben.<br></br><br></br>
              Bei weiteren Fragen nutzen Sie bitte unser&nbsp;
              <a
                className="accented"
                href="mailto:customer-service@pancon.de"
              >
                 Kontaktformular
              </a>.
            </p>
          </div>
        </div>
        <FooterDE />
      </div>
    );
  }
}

export default NotFound;
