import React, { Component } from "react";
import NavEN from "../Symbols/NavEN";
import { Link } from "react-router-dom";
import FooterEN from "../Symbols/FooterEN";
import About1 from "../../assets/about_1.jpg";
import AS1 from "../../assets/as_1.jpg";
import AS2 from "../../assets/as_2.jpg";
import AS3 from "../../assets/as_3.jpg";
import AS4 from "../../assets/as_4.jpg";
import AS5 from "../../assets/as_5.jpg";
import AS6 from "../../assets/as_6.jpg";
import { Helmet } from "react-helmet-async";
import "../../stylesheets/AboutUs.css";

class AutomotiveSolutions extends Component {
  constructor(props) {
    super(props);
    this.intro = React.createRef();
  }

  introScroll = () => {
    let element = this.intro.current;
    let headerOffset = 45;
    let elementPosition = element.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <div className="content">
        <Helmet>
          <title>Pancon - Automotive Solutions</title>
          <meta
            name="description"
            content="Discover high-quality connectors and innovative
connection solutions from Pancon - precise, reliable and ideal for automotive and industrial
and industrial applications. Individual connectors for your requirements."
          />
          <meta property="og:title" content="Pancon - Automotive Solutions" />
          <meta
            property="og:description"
            content="Discover high-quality connectors and innovative
connection solutions from Pancon - precise, reliable and ideal for automotive and industrial
and industrial applications. Individual connectors for your requirements."
          />
          <link
            rel="alternate"
            href="https://www.pancon.de/de/automotive-solutions"
            hrefLang="de"
          ></link>
          <link
            rel="alternate"
            href="https://www.pancon.de/en/automotive-solutions"
            hrefLang="en"
          ></link>
        </Helmet>
        <NavEN />
        <div className="hero auto-sol">
          <div className="red-bg-hero"></div>
          <div className="hero-content">
            <h1 className="hero-heading">Automotive Solutions</h1>
            <p className="hero-desc">
              Expertise in design, development, and production of highly complex
              connectors for automotive, commercial, and industrial industries.
            </p>
            <button onClick={this.introScroll} className="default inverted">
              Learn more
            </button>
          </div>
        </div>
        <div
          ref={this.intro}
          id="intro"
          className="section first flex center-h"
        >
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <h2>
                Extensive engineering expertise in productand process
                development
              </h2>
            </div>
            <div className="half">
              <p className="body">
                Pancon has over 50 years of experience in the areas of:
                <ul>
                  <li>
                    Moulding and Overmoulding of connectors and ECU applications
                  </li>
                  <li>Assembly of connector applications</li>
                  <li>Customer specific solutions</li>
                  <li>Pressfit solutions</li>
                  <li>THR/SMD connectors</li>
                  <li>IDC applications</li>
                </ul>
              </p>
            </div>
            <div className="half w-image">
              <img src={About1}></img>
            </div>
          </div>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2>Solutions</h2>
            </div>
            <p className="body">
              One of Pancon{"’"}s many strengths is the design and development
              of customized interconnect solutions. Pancon offers a wide array
              of modified standard products to meet special requirements and
              complex customer specific designs.
            </p>
            <div className="flex as-top">
              <div className="two-thirds">
                <h3 className="as">IDC Applications</h3>
                <p className="body as">
                  Insulation Displacement Connections is a straight forward and
                  secure connection between the wire or cable to the terminal.
                  The cable is pressed into the insulation displacement contact
                  of the connector, which cuts through the wire insulation to
                  make the electrical connection. Available in many AWG wire
                  ranges with single and double-row applications. Easy operation
                  of assembly Additional strain relief options.
                </p>
              </div>
              <div className="third flex center-h center-v">
                <img src={AS1}></img>
              </div>
            </div>
            <div className="flex as-top first">
              <div className="two-thirds">
                <h3 className="as">THR/SMD connections</h3>
                <p className="body as">
                  <ul>
                    <li>Through Hole Reflow</li>
                    <li>
                      Soldering for the components in PCB holes or Smart Mount
                      Devices for soldering on PCBpads.
                    </li>
                    <li>
                      The use of advanced materials makes these terminations
                      possible for the application of hot air soldering. This is
                      a fastand reliable connection method even for high pin
                      count devises.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="third flex center-h center-v">
                <img src={AS2}></img>
              </div>
            </div>
            <div className="flex as-top">
              <div className="two-thirds">
                <h3 className="as">Pressfit / Compliant Zone solutions</h3>
                <p className="body as">
                  <ul>
                    <li>Pressfit zone of 0.8mm and 0.6mm available</li>
                    <li>
                      Pressfit connections for contacts terminated to through
                      hole PCB’s
                    </li>
                    <li>Suitable for various PCB’s and holediameters</li>
                    <li>Custom size Pressfit zones available onrequest</li>
                  </ul>
                </p>
              </div>
              <div className="third flex center-h center-v">
                <img src={AS3}></img>
              </div>
            </div>
            <div className="flex as-top">
              <div className="two-thirds">
                <h3 className="as">
                  Connector assembly processes to meet customer demand including
                  highly automated production
                </h3>
                <p className="body as">
                  Pancon develops the manufacturing process to meet customer
                  demand with a degree of automation in our production
                  facilities to assure steady flow of product.
                </p>
              </div>
              <div className="third flex center-h center-v">
                <img src={AS4}></img>
              </div>
            </div>
            <div className="flex as-top">
              <div className="two-thirds">
                <h3 className="as">
                  Insert molding and Over-molding of connectors and ECU
                  applications
                </h3>
                <p className="body as">
                  This technology enables Pancon to realize simple and complex
                  assembly designs with high precision molded solutions
                  including IDC and Pressfit termination within the assembly.
                  Fully automated processing for unique applications.
                </p>
                <ul>
                  <li>
                    Contact unit for the rear differential shaft for Ford C2
                    platform
                  </li>
                  <li>Automotive Motor Housing for start-stop oil pump</li>
                </ul>
              </div>
              <div className="third flex center-h center-v">
                <img src={AS5}></img>
              </div>
            </div>
            <div className="flex as-top">
              <div className="two-thirds">
                <h3 className="as">
                  Molding and Over-molding of connectors and ECU applications
                  (semi-automated)
                </h3>
                <p className="body as">
                  In addition to fully automated production, Pancon also offers
                  lower production support and capabilities of manual
                  production. Applications:
                </p>
                <ul>
                  <li>Charge Level Indications for electric vehicles</li>
                  <li>Touch Panels</li>
                </ul>
              </div>
              <div className="third flex center-h center-v">
                <img src={AS6}></img>
              </div>
            </div>
          </div>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="half flex center-v center-h">
              <p className="eye-catcher">Interested?</p>
            </div>
            <div className="half flex center-v center-h col">
              <Link className="default" to="/en/contact">
                <span>Contact us now</span>
              </Link>
              <p className="body">We are looking forward to your inquiry!</p>
            </div>
          </div>
        </div>
        <FooterEN />
      </div>
    );
  }
}

export default AutomotiveSolutions;
