import React, { Component } from "react";
import NavEN from "../Symbols/NavEN";
import FooterEN from "../Symbols/FooterEN";
import "../../stylesheets/Kontakt.css";
import { Helmet } from "react-helmet-async";
import NewsArchiveCard from "../elements/NewsArchiveCard";

class News_OverviewEN extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: [],
    };
  }

  componentDidMount() {
    this.fetchNews();
  }

  fetchNews = async () => {
    let offset = "";
    let count = 10;
    let records = [];
    let records_filtered = []
    for (let i = 0; i < count; i++) {
      const response = await fetch(
        `https://api.airtable.com/v0/appTEn3LDZ56nauxj/tbleN5eldbuGJNbYB?offset=${offset}`, { headers: { 'Authorization': 'Bearer patSxqK40aDpd0iY6.beedbae8aef0d8ef37eaa0268af4654eb5113643efb69777ef67367c2031ee09' } }
      );
      const data = await response.json();
      records = records.concat(data.records);
      if (data.offset) {
        offset = data.offset;
      } else {
        break;
      }
    }

    records.forEach((news, key) => {
      if (Object.keys(news.fields).length !== 0 && "Title_EN" in news.fields && "Link_EN" in news.fields && "Published" in news.fields) {
        records_filtered.push(news)
      }
    });

    this.setState({
      news: records_filtered,
    });
  };

  render() {
    return (
      <div className="content">
        <Helmet>
          <title>Pancon - News</title>
          <meta
            name="description"
            content="Subscribe to the Pancon newsletter and stay informed about innovative
innovative technologies, new products and industry trends. Exclusive knowledge
for anyone looking for connection technologies - straight to your inbox!"
          />
          <meta property="og:title" content="Pancon - News" />
          <meta
            property="og:description"
            content="Subscribe to the Pancon newsletter and stay informed about innovative
innovative technologies, new products and industry trends. Exclusive knowledge
for anyone looking for connection technologies - straight to your inbox!"
          />
          <link
            rel="alternate"
            href="https://www.pancon.de/de/news"
            hrefLang="de"
          ></link>
          <link
            rel="alternate"
            href="https://www.pancon.de/en/news"
            hrefLang="en"
          ></link>
        </Helmet>
        <NavEN />
        <div className="section flex first intro center-h less-bot">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h1>News</h1>
            </div>
            <div className="flex news-contt">
              {this.state.news.map((news, i) => (
                <NewsArchiveCard key={i} lang="en" news={news} />
              ))}
            </div>
          </div>
        </div>
        <FooterEN />
      </div>
    );
  }
}

export default News_OverviewEN;
