import React, { Component } from "react";
import NavEN from "../Symbols/NavEN";
import FooterEN from "../Symbols/FooterEN";
import ZubehoerToolCard from "../elements/ZubehoerToolCard";
import "../../stylesheets/Produkte.css";
import { Helmet } from "react-helmet-async";

class ZuebhoerTools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: this.props.active,
      tools: [],
      zubehoer: [],
      offset: "",
    };
  }

  componentDidMount() {
    this.fetchTools();
    this.fetchZubehoer();
  }

  fetchTools = async () => {
    let offset = "";
    let count = 10;
    let records = [];
    for (let i = 0; i < count; i++) {
      const response = await fetch(
        `https://api.airtable.com/v0/app1t0Kdg1xsbWoWn/tblOx9RgM1uGIBYxP?offset=${offset}`, { headers: { 'Authorization': 'Bearer patSxqK40aDpd0iY6.beedbae8aef0d8ef37eaa0268af4654eb5113643efb69777ef67367c2031ee09' } }
      );
      const data = await response.json();
      records = records.concat(data.records);
      if (data.offset) {
        offset = data.offset;
      } else {
        break;
      }
    }
    this.setState({
      tools: records,
    });
  };

  fetchZubehoer = async () => {
    let offset = "";
    let count = 10;
    let records = [];
    for (let i = 0; i < count; i++) {
      const response = await fetch(
        `https://api.airtable.com/v0/app1t0Kdg1xsbWoWn/tbldh1d0aFS32WrmN?offset=${offset}`, { headers: { 'Authorization': 'Bearer patSxqK40aDpd0iY6.beedbae8aef0d8ef37eaa0268af4654eb5113643efb69777ef67367c2031ee09' } }
      );
      const data = await response.json();
      records = records.concat(data.records);
      if (data.offset) {
        offset = data.offset;
      } else {
        break;
      }
    }
    this.setState({
      zubehoer: records,
    });
  };

  changeView = (e, target) => {
    this.setState({
      active: target,
    });
  };

  render() {
    return (
      <div className="content">
        <Helmet>
          <title>Pancon - Accessories {"&"} Tools</title>
          <meta
            name="description"
            content="Pancon, based in Germany, is producing and distributing electromechanical components since 1968."
          />
          <meta property="og:title" content="Pancon - Accessories und Tools" />
          <meta
            property="og:description"
            content="Pancon, based in Germany, is producing and distributing electromechanical components since 1968."
          />
          <link
            rel="alternate"
            href="https://www.pancon.de/de/zubehoer-und-tools"
            hrefLang="de"
          ></link>
          <link
            rel="alternate"
            href="https://www.pancon.de/en/accessories-and-tools"
            hrefLang="en"
          ></link>
        </Helmet>
        <NavEN />
        <div className="section first">
          <div className="heading1-cont flex center-v center-h">
            <div className="heading-deco m-right-3"></div>
            <h1>Accessories and Tools</h1>
          </div>
          <div className="flex center-h">
            <button
              className={
                this.state.active === "Zubehör"
                  ? "default"
                  : "default not-active"
              }
              onClick={(event) => this.changeView(event, "Zubehör")}
            >
              Accessories
            </button>
            <button
              className={
                this.state.active === "Tools" ? "default" : "default not-active"
              }
              onClick={(event) => this.changeView(event, "Tools")}
            >
              Tools
            </button>
          </div>
        </div>
        <div>
          <div>
            <div className="section flex" id="zubehoer-tools">
              {this.state.active === "Tools"
                ? this.state.tools.map((product, i) => (
                  <ZubehoerToolCard key={i} product={product} lang="en" type="tools" />
                ))
                : this.state.zubehoer.map((product, i) => (
                  <ZubehoerToolCard
                    key={i}
                    product={product}
                    lang="en"
                    type="accessories"
                  />
                ))}
            </div>
          </div>
        </div>
        <FooterEN />
      </div>
    );
  }
}

export default ZuebhoerTools;
