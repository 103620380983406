import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import FooterDE from "../Symbols/FooterDE";
import "../../stylesheets/Produkte.css";
import { Helmet } from "react-helmet-async";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";

class Produkt extends Component {
  constructor(props) {
    super(props);
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    this.state = {
      file: null,
      numPages: 0,
      pageNumber: 1,
      product: [],
    };
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  nextPage = () => {
    const currentPageNumber = this.state.pageNumber;
    let nextPageNumber;

    if (currentPageNumber + 1 > this.state.numPages) {
      nextPageNumber = 1;
    } else {
      nextPageNumber = currentPageNumber + 1;
    }

    this.setState({
      pageNumber: nextPageNumber,
    });
  };

  backPage = () => {
    const currentPageNumber = this.state.pageNumber;
    let nextPageNumber;

    if (currentPageNumber - 1 < 1) {
      nextPageNumber = 1;
    } else {
      nextPageNumber = currentPageNumber - 1;
    }

    this.setState({
      pageNumber: nextPageNumber,
    });
  };

  componentDidMount() {
    fetch(
      `https://api.airtable.com/v0/app1t0Kdg1xsbWoWn/Produkte/${window.location.pathname.split("/")[3]
      }`, { headers: { 'Authorization': 'Bearer patSxqK40aDpd0iY6.beedbae8aef0d8ef37eaa0268af4654eb5113643efb69777ef67367c2031ee09' } }
    )
      .then((resp) => resp.json())
      .then((data) => {
        this.setState({ product: data, file: data.fields.Datenblatt_New[0].url });
      })
      .catch((err) => {
        // Error :(
      });
  }

  render() {
    const { pageNumber, numPages } = this.state;
    if (
      this.state.product &&
      Object.keys(this.state.product).length !== 0 &&
      Object.getPrototypeOf(this.state.product) === Object.prototype
    ) {
      return (
        <div className="content">
          <Helmet>
            <title>Pancon - {this.state.product.fields.Name_Neu}</title>
            <meta
              name="description"
              content="Pancon bietet verschiedene Arten von Steckverbindern an. Unsere
Produkte sind die optimale Lösung für Ihre Anforderungen im Bereich der
Konnektoren."
            />
            <meta
              property="og:title"
              content={"Pancon - " + this.state.product.fields.Name_Neu}
            />
            <meta
              property="og:description"
              content="Pancon bietet verschiedene Arten von Steckverbindern an. Unsere
Produkte sind die optimale Lösung für Ihre Anforderungen im Bereich der
Konnektoren."            />
          </Helmet>
          <NavDE />
          <div className="section first flex center-v center-h">
            <div className="medium flex center-h product-p">
              <div className="heading1-cont flex center-v center-h">
                <div className="heading-deco m-right-3"></div>
                <h1>{this.state.product.fields.Name_Neu}</h1>
              </div>
              <div className="flex center-h full-width col center-v">
                {this.state.product.fields.Bild != undefined &&
                  <img className="product-img" src={this.state.product.fields.Bild[0].url}></img>
                }
                <p className="body">
                  {this.state.product.fields.Marketing_Text ?
                    this.state.product.fields.Marketing_Text : this.state.product.fields.Beschreibung_DE_Neu
                  }
                </p>
              </div>
              <div className="card-cont small">
                <div className="card small">
                  <div className="card-desc flex center-h col">
                    <span className="prod-attr">
                      {this.state.product.fields.Produktreihe}
                    </span>
                    <span className="prod-attr-desc">Produktreihe</span>
                  </div>
                  <div className="card-desc flex center-h col">
                    <span className="prod-attr">
                      {this.state.product.fields.Kategorie.join(", ")}
                    </span>
                    <span className="prod-attr-desc">Kategorie</span>
                  </div>
                  <div className="card-desc flex center-h col">
                    <span className="prod-attr">
                      {this.state.product.fields.Pitch}
                    </span>
                    <span className="prod-attr-desc">Pitch</span>
                  </div>
                  <div className="card-desc flex center-h col">
                    <span className="prod-attr">
                      {this.state.product.fields.Gender_DE}
                    </span>
                    <span className="prod-attr-desc">Geschlecht</span>
                  </div>
                  <div className="card-desc flex center-h col">
                    <span className="prod-attr">
                      {this.state.product.fields.Number_of_Rows}
                    </span>
                    <span className="prod-attr-desc">Reihenanzahl</span>
                  </div>
                  <div className="card-desc flex center-h col">
                    <span className="prod-attr">
                      {this.state.product.fields.Polarized_DE}
                    </span>
                    <span className="prod-attr-desc">Polarisiert</span>
                  </div>
                  <div className="card-desc flex center-h col">
                    <span className="prod-attr">
                      {this.state.product.fields.Profile}
                    </span>
                    <span className="prod-attr-desc">Profil</span>
                  </div>
                  <div className="card-desc flex center-h col">
                    <span className="prod-attr">
                      {this.state.product.fields.Locating_Peg_DE}
                    </span>
                    <span className="prod-attr-desc">Positionierungsstift</span>
                  </div>
                  <div className="card-desc flex center-h col">
                    <span className="prod-attr">
                      {this.state.product.fields.Orientation}
                    </span>
                    <span className="prod-attr-desc">Orientierung</span>
                  </div>
                  <div className="card-desc flex center-h col">
                    <span className="prod-attr">
                      {this.state.product.fields.Elevated_DE}
                    </span>
                    <span className="prod-attr-desc">Erhöht</span>
                  </div>
                  <div className="card-desc flex center-h col">
                    <span className="prod-attr">
                      {this.state.product.fields.Connectivity_DE}
                    </span>
                    <span className="prod-attr-desc">Konnektivität</span>
                  </div>
                </div>
              </div>
              {this.state.file ? (
                <div className="flex col center-h m-top-4">
                  <h2>Datenblatt</h2>
                  <Document
                    file={this.state.file}
                    onLoadSuccess={this.onDocumentLoadSuccess}
                  >
                    <Page pageNumber={pageNumber} />
                  </Document>
                  <div id="navigation_controls" className="flex col center-h">
                    {this.state.file ? (
                      <p className="body center">
                        Seite {pageNumber} von {numPages}
                      </p>
                    ) : null}
                    <div className="flex center-h">
                      <button onClick={this.backPage} className="default m-right-1">
                        Vorherige Seite
                      </button>
                      <button onClick={this.nextPage} className="default">
                        Nächste Seite
                      </button>
                    </div>
                  </div>
                  <a
                    target="_blank"
                    href={this.state.product.fields.Datenblatt_New[0].url}
                  >
                    <div className="flex center-h marg-top">
                      <button className="default">Download</button>
                    </div>
                  </a>
                </div>
              ) : null}
            </div>
          </div>
          <FooterDE />
        </div>
      );
    }
  }
}

export default Produkt;
