import React, { Component } from "react";
import NavEN from "../Symbols/NavEN";
import FooterEN from "../Symbols/FooterEN";
import DistributorEl from "../elements/DistributorEl";
import "../../stylesheets/Distribution.css";
import { Helmet } from "react-helmet-async";

class DistributionEN extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: "Europa",
      active_c: "",
      visible_dists: [],
      dists: [],
      offset: "",
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let offset = "";
    let count = 10;
    let records = [];
    for (let i = 0; i < count; i++) {
      const response = await fetch(
        `https://api.airtable.com/v0/app3vTiQfUs5SKScQ/tblWMZL0IRJhXNV6u?offset=${offset}`, { headers: { 'Authorization': 'Bearer patSxqK40aDpd0iY6.beedbae8aef0d8ef37eaa0268af4654eb5113643efb69777ef67367c2031ee09' } }
      );
      const data = await response.json();
      records = records.concat(data.records);
      if (data.offset) {
        offset = data.offset;
      } else {
        break;
      }
    }
    this.setState({
      dists: records,
    });
  };

  changeView = (e, target) => {
    this.setState({
      active: target,
      visible_dists: [],
    });
  };

  changeCountry = (e, target) => {
    let visible_dists = this.state.dists.filter(function (dist) {
      return dist.fields.Land_EN === target;
    });
    this.setState({
      active_c: target,
      visible_dists: visible_dists,
    });
  };

  render() {
    return (
      <div className="content">
        <Helmet>
          <title>Pancon - Distribution</title>
          <meta
            name="description"
            content="We work together with various distributors on the market for
electromechanical components market. We have the solution, our regional
regional and international partners have our products in stock."
          />
          <meta property="og:title" content="Pancon - Distribution" />
          <meta
            property="og:description"
            content="We work together with various distributors on the market for
electromechanical components market. We have the solution, our regional
regional and international partners have our products in stock."
          />
          <link
            rel="alternate"
            href="https://www.pancon.de/de/distribution"
            hrefLang="de"
          ></link>
          <link
            rel="alternate"
            href="https://www.pancon.de/en/distribution"
            hrefLang="en"
          ></link>
        </Helmet>
        <NavEN />
        <div className="section first less-bot">
          <div className="heading1-cont flex center-v center-h">
            <div className="heading-deco m-right-3"></div>
            <h1>Distribution</h1>
          </div>
          <div className="flex center-h marg-top">
            <button
              className={
                this.state.active === "Europa"
                  ? "default"
                  : "default not-active"
              }
              onClick={(event) => this.changeView(event, "Europa")}
            >
              Europe
            </button>
            <button
              className={
                this.state.active === "Nordamerika"
                  ? "default"
                  : "default not-active"
              }
              onClick={(event) => this.changeView(event, "Nordamerika")}
            >
              North America
            </button>
            <button
              className={
                this.state.active === "Asien" ? "default" : "default not-active"
              }
              onClick={(event) => this.changeView(event, "Asien")}
            >
              Asia
            </button>
          </div>
        </div>
        <div className="less-bot section flex center-h">
          <div className="center-mob medium flex">
            {this.state.active == "Europa" ? (
              <div
                onClick={(event) => this.changeCountry(event, "Greece")}
                className="dist-country"
              >
                Greece
              </div>
            ) : null}
            {this.state.active == "Europa" ? (
              <div
                onClick={(event) => this.changeCountry(event, "Poland")}
                className="dist-country"
              >
                Poland
              </div>
            ) : null}
            {this.state.active == "Europa" ? (
              <div
                onClick={(event) => this.changeCountry(event, "Finland")}
                className="dist-country"
              >
                Finland
              </div>
            ) : null}
            {this.state.active == "Europa" ? (
              <div
                onClick={(event) => this.changeCountry(event, "Germany")}
                className="dist-country"
              >
                Germany
              </div>
            ) : null}
            {this.state.active == "Europa" ? (
              <div
                onClick={(event) => this.changeCountry(event, "Italy")}
                className="dist-country"
              >
                Italy
              </div>
            ) : null}
            {this.state.active == "Europa" ? (
              <div
                onClick={(event) => this.changeCountry(event, "France")}
                className="dist-country"
              >
                France
              </div>
            ) : null}
            {this.state.active == "Europa" ? (
              <div
                onClick={(event) => this.changeCountry(event, "UK")}
                className="dist-country"
              >
                UK
              </div>
            ) : null}
            {this.state.active == "Europa" ? (
              <div
                onClick={(event) => this.changeCountry(event, "Spain")}
                className="dist-country"
              >
                Spain
              </div>
            ) : null}
            {this.state.active == "Europa" ? (
              <div
                onClick={(event) => this.changeCountry(event, "Switzerland")}
                className="dist-country"
              >
                Switzerland
              </div>
            ) : null}
            {this.state.active == "Nordamerika" ? (
              <div
                onClick={(event) => this.changeCountry(event, "USA")}
                className="dist-country"
              >
                USA
              </div>
            ) : null}
            {this.state.active == "Asien" ? (
              <div
                onClick={(event) => this.changeCountry(event, "China")}
                className="dist-country"
              >
                China
              </div>
            ) : null}
            {this.state.active == "Asien" ? (
              <div
                onClick={(event) => this.changeCountry(event, "Malaysia")}
                className="dist-country"
              >
                Malaysia
              </div>
            ) : null}
            {this.state.active == "Asien" ? (
              <div
                onClick={(event) => this.changeCountry(event, "Singapor")}
                className="dist-country"
              >
                Singapor
              </div>
            ) : null}
            {this.state.active == "Asien" ? (
              <div
                onClick={(event) => this.changeCountry(event, "Taiwan")}
                className="dist-country"
              >
                Taiwan
              </div>
            ) : null}
          </div>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            {this.state.visible_dists.map((dist, i) => (
              <DistributorEl key={i} dist={dist} />
            ))}
          </div>
        </div>
        <FooterEN />
      </div>
    );
  }
}

export default DistributionEN;
